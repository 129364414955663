.App {
  font-family: sans-serif;
  text-align: center;
}

.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(73, 67, 96, 0.74);
}

.glass:hover {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgba(73, 67, 96, 0.74);

}

.hero-message {
  margin: 0;
  /* display: inline-block; */
  /* color: #a363e8; */
  font-size: 14px;
  font-family: var(--special-font-bold);
  text-transform: uppercase;
  /* letter-spacing: .2em; */
  background-color: rgba(255, 255, 255, .05);
  position: relative;
  line-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 5px;
  /* width: 350px; */
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
  /* text-align: center; */

}

.new-feature-message {
  color: limegreen;
  text-align: left;
  font-size: 0.9rem;
  margin-top: 20px;
  font-weight: bold;
}

.ClickedPlayerTotalTable {
  margin-top: 20px;
}

.SangTable {
  margin-top: 5px;
  /* display: inline-flex; */
}

.updateTimeSection {
  width: 350px;
  margin-left: 5px;
  font-size: 0.72rem;
  text-align: left;
  font-weight: 700
}

.bovada-section {
  width: 238px;
  margin-top: 15px;
  margin-left: 5px;
  font-size: 0.72rem;
  text-align: left;
  font-weight: 700
}

.updateTimeSection2 {
  width: 350px;
  margin-left: 15px;
  font-size: 0.62rem;
  text-align: left;
}

.select-player-message {
  text-align: left;
  /* margin-left: 32px; */
  font-size: 0.9rem;
  font-weight: bold;
}

.patreonSection {
  margin-top: 10px;
  width: 350px;
  margin-left: 10px;
  font-size: 0.82rem;
}


.button {
  border-radius: 10px;
  background-color: #f4511e;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  width: 250px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.bovada-button {
  border-radius: 5px;
  background-color: #f4511e;
  border-color: black;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  transition: all 0.5s;
  cursor: pointer;
  margin-left: 24px;
  width: 70px;
  margin-top: 48px;

  /* margin: 5px; */

}

.button2 {
  border-radius: 5px;
  background-color: #f4511e;
  border-color: black;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  transition: all 0.5s;
  cursor: pointer;
  margin-left: 12px;
  width: 60px;

  /* margin: 5px; */
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}



.invis-mobile {
  display: none;
}

.invis-mobile-header {
  display: none;
}

.trade-value-theme {
  margin-left: 4px;
  display: flex;
  margin-bottom: 12px;
}

.trade-button {
  border-radius: 5px;
  background-color: limegreen;
  border-color: black;
  border: none;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  transition: all 0.5s;
  cursor: pointer;
  margin-left: 12px;
  width: 200px;
}

@media screen and (min-width: 480px) {
  .invis-mobile-header {
    display: table-cell;
  }

  .invis-mobile {
    display: block;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;